import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AppBar, Toolbar, Typography, styled, Box } from "@mui/material";
import Login from "./components/Login";
import PrivateRoute from "./utils/PrivateRoute";
import WorkerRoute from "./utils/WorkerRoute";
import PendingNodes from "./components/PendingNodes";
import AllUserNodes from "./components/UserNodes";
import Nodes from "./components/Nodes";
import Promos from "./components/Promos";
import Users from "./components/Users";
import Merchant from "./components/Merchant";
import Invoices from "./components/Invoices";
import NodeDetails from "./components/NodeDetails";
import SingleUserUserNodes from "./components/SingleUserUserNodes";
import Stats from "./components/Stats";
import Uptime from "./components/Uptime";
import WarningSettings from "./components/Warning";
import Tasks from "./components/Tasks";
import Dashboard from "./components/Dashboard";
import SoftsPage from "./components/Softs";
import ExpiredNodes from "./components/ExpiredNodes";
import { Link } from "react-router-dom";
import { getCurrentUser } from "./services/authService";

const theme = createTheme();

const Header = () => {
    const location = useLocation();
    const user = getCurrentUser();

    return (
        <AppBar position="static">
            <StyledToolbar
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2rem",
                }}
            >
                <Typography variant="h6">Admin Panel</Typography>
                {location.pathname !== "/login" && (
                    <>
                        {user?.isSuperAdmin ? (
                            <Link to="/dashboard">Dashboard</Link>
                        ) : null}
                        {user?.isSuperAdmin ? (
                            <Link to="/nodes">Nodes</Link>
                        ) : null}
                        <Link to="/pending">Pending Nodes</Link>
                        {user?.isSuperAdmin ? (
                            <Link to="/expired">Expired Nodes</Link>
                        ) : null}
                        <Link to="/all-users-nodes">All Users Nodes</Link>
                        {user?.isSuperAdmin ? (
                            <Link to="/promos">Promos</Link>
                        ) : null}
                        {user?.isSuperAdmin ? (
                            <Link to="/users">Users</Link>
                        ) : null}
                        {user?.isSuperAdmin ? (
                            <Link to="/invoices">Invoices</Link>
                        ) : null}
                        <Link to="/uptime">Uptime</Link>
                        {user?.isSuperAdmin ? (
                            <Link to="/tasks">Tasks</Link>
                        ) : null}
                        {user?.isSuperAdmin ? (
                            <Link to="/merchant">Merchant</Link>
                        ) : null}
                        {user?.isSuperAdmin ? (
                            <Link to="/warning">Warning</Link>
                        ) : null}
                        {user?.isSuperAdmin ? (
                            <Link to="/stats">Stats</Link>
                        ) : null}
                        <Link to="/softs">Softs</Link>
                    </>
                )}
            </StyledToolbar>
        </AppBar>
    );
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Header />
                <Box sx={{ margin: "50px" }}>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/nodes"
                            element={
                                <PrivateRoute>
                                    <Nodes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/promos"
                            element={
                                <PrivateRoute>
                                    <Promos />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <PrivateRoute>
                                    <Users />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/merchant"
                            element={
                                <PrivateRoute>
                                    <Merchant />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/invoices"
                            element={
                                <PrivateRoute>
                                    <Invoices />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/expired"
                            element={
                                <PrivateRoute>
                                    <ExpiredNodes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/pending"
                            element={
                                <WorkerRoute>
                                    <PendingNodes />
                                </WorkerRoute>
                            }
                        />
                        <Route
                            path="/all-users-nodes"
                            element={
                                <WorkerRoute>
                                    <AllUserNodes />
                                </WorkerRoute>
                            }
                        />
                        <Route
                            path="/stats"
                            element={
                                <PrivateRoute>
                                    <Stats />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/uptime"
                            element={
                                <WorkerRoute>
                                    <Uptime />
                                </WorkerRoute>
                            }
                        />
                        <Route
                            path="/softs"
                            element={
                                <WorkerRoute>
                                    <SoftsPage />
                                </WorkerRoute>
                            }
                        />
                        <Route
                            path="/nodedetails/:nodeId"
                            element={
                                <PrivateRoute>
                                    <NodeDetails />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/:userId"
                            element={
                                <PrivateRoute>
                                    <SingleUserUserNodes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/tasks"
                            element={
                                <PrivateRoute>
                                    <Tasks />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/warning"
                            element={
                                <PrivateRoute>
                                    <WarningSettings />
                                </PrivateRoute>
                            }
                        />
                        <Route path="/" element={<Navigate to="/pending" />} />
                        <Route
                            path="*"
                            element={<Navigate to="/not-found" />}
                        />
                    </Routes>
                </Box>
            </Router>
        </ThemeProvider>
    );
}

const StyledToolbar = styled(Toolbar)(() => ({
    a: {
        fontSize: "16px",
        color: "white",
        textDecoration: "none",
    },
}));

export default App;
