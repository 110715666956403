import React, { useState } from "react";
import {
    Button,
    TextField,
    Container,
    Typography,
    TextareaAutosize,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ethers } from "ethers";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import axios from "axios";
import FaucetForm from "./FaucetForm"; // Импортируем новый компонент

const SoftsPage = () => {
    const [walletCount, setWalletCount] = useState(0);
    const [addresses, setAddresses] = useState("");
    const [logs, setLogs] = useState("");

    const handleGenerate = () => {
        const wallets = [];
        for (let i = 0; i < walletCount; i++) {
            const wallet = ethers.Wallet.createRandom();
            wallets.push(wallet);
        }
        const data = wallets.map((wallet) => ({
            Address: wallet.address,
            "Private Key": wallet.privateKey,
            Mnemonic: wallet.mnemonic.phrase,
        }));
        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "wallets.csv");
    };

    const handleCheckAddresses = async () => {
        const inputAddresses = addresses
            .split("\n")
            .map((line) => line.trim())
            .filter(Boolean);
        const offlineAddresses = [];
        let logMessages = "";

        for (const address of inputAddresses) {
            try {
                const response = await axios.get(
                    `https://api.testnet-3.elixir.xyz/validators/${address}`
                );
                const validator = response.data.validator;
                const logMessage = `Adress: ${address}, name: ${
                    validator.display_name
                }, online: ${validator.online}, day uptime: ${(
                    validator.uptime_day * 100
                ).toFixed(2)}%, month uptime: ${(
                    validator.uptime_month * 100
                ).toFixed(2)}%`;
                logMessages += logMessage + "\n";
                setLogs(logMessages);

                if (!validator.online) {
                    offlineAddresses.push(address);
                }
                await new Promise((resolve) => setTimeout(resolve, 1000));
            } catch (error) {
                const errorMessage = `Ошибка при запросе к адресу ${address}: ${error.message}`;
                logMessages += errorMessage + "\n";
                setLogs(logMessages);
            }
        }

        if (offlineAddresses.length > 0) {
            const blob = new Blob([offlineAddresses.join("\n")], {
                type: "text/plain;charset=utf-8;",
            });
            saveAs(blob, "offline_addresses.txt");
        } else {
            console.log("No offline addresses found.");
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Softs
            </Typography>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        Generate EVM Wallets CSV
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        label="Number of Wallets"
                        type="number"
                        value={walletCount}
                        onChange={(e) => setWalletCount(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerate}
                    >
                        Generate
                    </Button>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Check Elixir Nodes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TextareaAutosize
                        minRows={10}
                        placeholder="Enter addresses, one per line"
                        value={addresses}
                        onChange={(e) => setAddresses(e.target.value)}
                        style={{ width: "100%", marginBottom: "20px" }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCheckAddresses}
                    >
                        Check Addresses
                    </Button>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{ marginTop: "20px" }}
                    >
                        Logs
                    </Typography>
                    <TextareaAutosize
                        minRows={10}
                        value={logs}
                        readOnly
                        style={{ width: "100%" }}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Ethereum Testnets Faucet</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FaucetForm />
                </AccordionDetails>
            </Accordion>
        </Container>
    );
};

export default SoftsPage;
